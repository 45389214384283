// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.select-input span {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.select-input select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.select-input select:focus {
  outline: none;
  border-color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectInput/SelectInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,kCAAkC;EAClC,sBAAsB;EACtB,gBAAgB;EAChB,wBAAwB;EACxB,qBAAqB;EACrB,4BAA4B;EAC5B,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".select-input {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.select-input span {\n  font-size: 14px;\n  color: #555;\n  margin-bottom: 5px;\n}\n\n.select-input select {\n  width: 100%;\n  padding: 10px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: white;\n  transition: border-color 0.3s ease;\n  box-sizing: border-box;\n  appearance: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  background-repeat: no-repeat;\n  background-position: right 10px center;\n}\n\n.select-input select:focus {\n  outline: none;\n  border-color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
