// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-listing-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.create-listing-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-listing-form > * {
  margin-bottom: 20px;
  width: 100%; /* Ensure all direct children of the form take full width */
}

/* Style for all inputs, textareas, and selects */
.create-listing-form input,
.create-listing-form textarea,
.create-listing-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Style for the button */
.create-listing-form button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-listing-form button:hover {
  background-color: #0056b3;
}

/* Ensure the image input doesn't stretch its content */
.create-listing-form .image-input input[type="file"] {
  width: auto;
}

@media (max-width: 768px) {
  .create-listing-page {
    padding: 20px 10px;
  }

  .create-listing-form {
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/CreateListingPage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,WAAW,EAAE,2DAA2D;AAC1E;;AAEA,iDAAiD;AACjD;;;EAGE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA,yBAAyB;AACzB;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,uDAAuD;AACvD;EACE,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".create-listing-page {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 40px 20px;\n}\n\n.create-listing-form {\n  background-color: #ffffff;\n  border-radius: 8px;\n  padding: 30px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.create-listing-form > * {\n  margin-bottom: 20px;\n  width: 100%; /* Ensure all direct children of the form take full width */\n}\n\n/* Style for all inputs, textareas, and selects */\n.create-listing-form input,\n.create-listing-form textarea,\n.create-listing-form select {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n}\n\n/* Style for the button */\n.create-listing-form button {\n  width: 100%;\n  padding: 12px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.create-listing-form button:hover {\n  background-color: #0056b3;\n}\n\n/* Ensure the image input doesn't stretch its content */\n.create-listing-form .image-input input[type=\"file\"] {\n  width: auto;\n}\n\n@media (max-width: 768px) {\n  .create-listing-page {\n    padding: 20px 10px;\n  }\n\n  .create-listing-form {\n    padding: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
