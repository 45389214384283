// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-icon {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.menu-icon__line {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.menu-icon:hover .menu-icon__line {
  background-color: #4CAF50;
}

@media (max-width: 768px) {
  .menu-icon {
    width: 22px;
    height: 18px;
  }

  .menu-icon__line {
    height: 2px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/MenuIcon/MenuIcon.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".menu-icon {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 25px;\n  height: 20px;\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 0;\n}\n\n.menu-icon__line {\n  width: 100%;\n  height: 3px;\n  background-color: #333;\n  transition: all 0.3s ease;\n}\n\n.menu-icon:hover .menu-icon__line {\n  background-color: #4CAF50;\n}\n\n@media (max-width: 768px) {\n  .menu-icon {\n    width: 22px;\n    height: 18px;\n  }\n\n  .menu-icon__line {\n    height: 2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
