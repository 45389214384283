// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.login-form input {
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.login-form button {
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".login-form {\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n}\n\n.login-form input {\n  margin-bottom: 10px;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.login-form button {\n  margin-top: 10px;\n  width: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
