// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.register-form input {
  margin-bottom: 10px;
}

.register-form button {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RegisterForm/RegisterForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".register-form {\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n}\n\n.register-form input {\n  margin-bottom: 10px;\n}\n\n.register-form button {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
