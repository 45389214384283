// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  color: #721c24;
  background-color: #f8d7da;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.error::before {
  content: '⚠️';
  margin-right: 0.5rem;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Error/Error.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,wBAAwB;EACxB,mBAAmB;EACnB,6BAA6B;EAC7B,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":[".error {\n  color: #721c24;\n  background-color: #f8d7da;\n  padding: 0.75rem 1.25rem;\n  margin-bottom: 1rem;\n  border: 1px solid transparent;\n  border-radius: 0.25rem;\n  font-size: 0.875rem;\n  line-height: 1.5;\n  display: flex;\n  align-items: center;\n}\n\n.error::before {\n  content: '⚠️';\n  margin-right: 0.5rem;\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
