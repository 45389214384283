// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.number-input span {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.number-input input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  -moz-appearance: textfield; /* Firefox */
}

.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input input:focus {
  outline: none;
  border-color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/NumberInput/NumberInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,kCAAkC;EAClC,sBAAsB;EACtB,0BAA0B,EAAE,YAAY;AAC1C;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".number-input {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.number-input span {\n  font-size: 14px;\n  color: #555;\n  margin-bottom: 5px;\n}\n\n.number-input input {\n  width: 100%;\n  padding: 10px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  transition: border-color 0.3s ease;\n  box-sizing: border-box;\n  -moz-appearance: textfield; /* Firefox */\n}\n\n.number-input input::-webkit-outer-spin-button,\n.number-input input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.number-input input:focus {\n  outline: none;\n  border-color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
