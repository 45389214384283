// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WatchlistPage.css */
.watchlist-title{
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.watchlist-page {
  padding: 20px;
}

.watchlist-loading,
.watchlist-error {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

.watchlist-listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .watchlist-listings {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/WatchlistPage.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;AAC3C;;;AAGA;EACE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["/* WatchlistPage.css */\n.watchlist-title{\n  font-size: 30px;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 30px;\n  margin-top: 10px;\n  text-align: center;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n\n.watchlist-page {\n  padding: 20px;\n}\n\n.watchlist-loading,\n.watchlist-error {\n  text-align: center;\n  margin-top: 50px;\n  font-size: 18px;\n}\n\n.watchlist-listings {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 20px;\n  margin-top: 20px;\n}\n\n@media (max-width: 768px) {\n  .watchlist-listings {\n    grid-template-columns: 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
