// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  color: #495057;
  text-decoration: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
}

.nav-link:hover {
  color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.nav-link.active {
  color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #007bff;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

@media (max-width: 768px) {
  .nav-link {
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/NavLink/NavLink.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,QAAQ;EACR,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,aAAa;EACf;AACF","sourcesContent":[".nav-link {\n  color: #495057;\n  text-decoration: none;\n  padding: 10px 20px;\n  margin: 0 5px;\n  border-radius: 4px;\n  transition: all 0.3s ease;\n  font-weight: 500;\n  position: relative;\n}\n\n.nav-link:hover {\n  color: #007bff;\n  background-color: rgba(0, 123, 255, 0.1);\n}\n\n.nav-link.active {\n  color: #007bff;\n  background-color: rgba(0, 123, 255, 0.1);\n}\n\n.nav-link::after {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  width: 0;\n  height: 2px;\n  background-color: #007bff;\n  transition: all 0.3s ease;\n  transform: translateX(-50%);\n}\n\n.nav-link:hover::after,\n.nav-link.active::after {\n  width: 100%;\n}\n\n@media (max-width: 768px) {\n  .nav-link {\n    width: 100%;\n    text-align: center;\n    margin: 5px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
