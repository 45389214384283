// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  background-color: #f0f4f8;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nav-link {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.nav-link:hover,
.nav-link.active {
  color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

@media (min-width: 769px) {
  .nav {
    padding: 15px 20px;
  }

  .nav-container {
    flex-direction: row;
    max-width: 1200px;
  }

  .nav-link {
    width: auto;
    margin: 0 10px;
    padding: 12px 24px;
    font-size: 18px;
  }

  .nav-link:hover,
  .nav-link.active {
    transform: translateY(-2px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Nav.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;EACxC,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;;EAEE,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;;EAEA;;IAEE,2BAA2B;EAC7B;AACF","sourcesContent":[".nav {\n  background-color: #f0f4f8;\n  padding: 10px 15px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  border-top: 1px solid #e0e0e0;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.nav-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.nav-link {\n  color: #333;\n  text-decoration: none;\n  padding: 10px 20px;\n  margin: 5px 0;\n  border-radius: 4px;\n  transition: all 0.3s ease;\n  font-weight: 600;\n  font-size: 16px;\n  width: 100%;\n  text-align: center;\n}\n\n.nav-link:hover,\n.nav-link.active {\n  color: #007bff;\n  background-color: rgba(0, 123, 255, 0.1);\n}\n\n@media (min-width: 769px) {\n  .nav {\n    padding: 15px 20px;\n  }\n\n  .nav-container {\n    flex-direction: row;\n    max-width: 1200px;\n  }\n\n  .nav-link {\n    width: auto;\n    margin: 0 10px;\n    padding: 12px 24px;\n    font-size: 18px;\n  }\n\n  .nav-link:hover,\n  .nav-link.active {\n    transform: translateY(-2px);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
