// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #007bff;
  color: white;
  box-sizing: border-box;
}

.button:hover {
  background-color: #0056b3;
}

.button.transparent {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.button.transparent:hover {
  background-color: rgba(0, 123, 255, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,uDAAuD;EACvD,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".button {\n  width: 100%;\n  padding: 12px 20px;\n  font-size: 16px;\n  font-weight: 500;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease, color 0.3s ease;\n  background-color: #007bff;\n  color: white;\n  box-sizing: border-box;\n}\n\n.button:hover {\n  background-color: #0056b3;\n}\n\n.button.transparent {\n  background-color: transparent;\n  color: #007bff;\n  border: 1px solid #007bff;\n}\n\n.button.transparent:hover {\n  background-color: rgba(0, 123, 255, 0.1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
