// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.popup p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX","sourcesContent":[".popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.popup {\n  background-color: white;\n  border-radius: 8px;\n  padding: 30px;\n  width: 90%;\n  max-width: 400px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.popup h2 {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 15px;\n}\n\n.popup p {\n  font-size: 16px;\n  color: #555;\n  margin-bottom: 20px;\n}\n\n.popup-buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
