// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailed-description {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detailed-description h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.detailed-description p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailedDescription/DetailedDescription.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".detailed-description {\n  background-color: #f8f9fa;\n  border-radius: 8px;\n  padding: 20px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.detailed-description h3 {\n  font-size: 20px;\n  color: #333;\n  margin-bottom: 15px;\n}\n\n.detailed-description p {\n  font-size: 16px;\n  line-height: 1.6;\n  color: #555;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
