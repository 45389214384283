// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logo img {
  max-height: 40px;
  width: auto;
}

.logo:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .logo img {
    max-height: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Logo/Logo.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".logo {\n  cursor: pointer;\n  transition: transform 0.3s ease;\n}\n\n.logo img {\n  max-height: 40px;\n  width: auto;\n}\n\n.logo:hover {\n  transform: scale(1.05);\n}\n\n@media (max-width: 768px) {\n  .logo img {\n    max-height: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
