// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  min-width: 180px;
  z-index: 1000;
}

.dropdown a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown a:hover {
  background-color: #f5f5f5;
  color: #4CAF50;
}

@media (max-width: 768px) {
  .dropdown {
    min-width: 150px;
  }

  .dropdown a {
    padding: 8px 15px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Dropdown/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;EACzC,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;AACF","sourcesContent":[".dropdown {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  background-color: #ffffff;\n  border-radius: 4px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  padding: 10px 0;\n  min-width: 180px;\n  z-index: 1000;\n}\n\n.dropdown a {\n  display: block;\n  padding: 10px 20px;\n  color: #333;\n  text-decoration: none;\n  transition: background-color 0.3s ease;\n}\n\n.dropdown a:hover {\n  background-color: #f5f5f5;\n  color: #4CAF50;\n}\n\n@media (max-width: 768px) {\n  .dropdown {\n    min-width: 150px;\n  }\n\n  .dropdown a {\n    padding: 8px 15px;\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
