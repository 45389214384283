// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.header__side {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.header__side:last-child {
  justify-content: flex-end;
}

.header__center {
  flex: 2;
  text-align: center;
  padding: 0 15px;
  font-size: 16px;
}

.header__center span {
  cursor: pointer;
  font-weight: 500;
  color: #333;
}

.header__center button {
  margin: 0 5px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.header__center button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (min-width: 769px) {
  .header {
    padding: 10px 20px;
  }

  .header__center {
    padding: 0 20px;
    font-size: 20px;
  }

  .header__center button {
    margin: 0 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,OAAO;EACP,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,wCAAwC;AAC1C;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 15px;\n  background-color: #ffffff;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.header__side {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.header__side:last-child {\n  justify-content: flex-end;\n}\n\n.header__center {\n  flex: 2;\n  text-align: center;\n  padding: 0 15px;\n  font-size: 16px;\n}\n\n.header__center span {\n  cursor: pointer;\n  font-weight: 500;\n  color: #333;\n}\n\n.header__center button {\n  margin: 0 5px;\n  padding: 6px 12px;\n  border: none;\n  border-radius: 4px;\n  background-color: #4CAF50;\n  color: white;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  font-size: 14px;\n}\n\n.header__center button:hover {\n  background-color: #45a049;\n  transform: translateY(-2px);\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n}\n\n@media (min-width: 769px) {\n  .header {\n    padding: 10px 20px;\n  }\n\n  .header__center {\n    padding: 0 20px;\n    font-size: 20px;\n  }\n\n  .header__center button {\n    margin: 0 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
