// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.image-input label {
  display: block;
  margin-bottom: 10px;
}

.image-input input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

.upload-progress {
  margin-top: 10px;
}

.upload-progress progress {
  width: 100%;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageInput/ImageInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".image-input {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.image-input label {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.image-input input[type=\"file\"] {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.upload-progress {\n  margin-top: 10px;\n}\n\n.upload-progress progress {\n  width: 100%;\n}\n\n.image-preview {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin-top: 20px;\n}\n\n.image-preview img {\n  width: 100px;\n  height: 100px;\n  object-fit: cover;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
