// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50; /* Success green */
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 80%;
  width: auto;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  animation: slideInDown 0.5s ease-out, fadeOut 0.5s ease-in 2.5s forwards;
}

.alert.error {
  background-color: #F44336; /* Error red */
}

.alert.warning {
  background-color: #FFA000; /* Warning amber */
}

.alert.info {
  background-color: #2196F3; /* Info blue */
}

@keyframes slideInDown {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,yBAAyB,EAAE,kBAAkB;EAC7C,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,wEAAwE;AAC1E;;AAEA;EACE,yBAAyB,EAAE,cAAc;AAC3C;;AAEA;EACE,yBAAyB,EAAE,kBAAkB;AAC/C;;AAEA;EACE,yBAAyB,EAAE,cAAc;AAC3C;;AAEA;EACE;IACE,iCAAiC;IACjC,UAAU;EACZ;EACA;IACE,6BAA6B;IAC7B,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".alert {\n  position: fixed;\n  top: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: #4CAF50; /* Success green */\n  color: white;\n  padding: 15px 20px;\n  border-radius: 4px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  max-width: 80%;\n  width: auto;\n  text-align: center;\n  font-size: 16px;\n  line-height: 1.4;\n  animation: slideInDown 0.5s ease-out, fadeOut 0.5s ease-in 2.5s forwards;\n}\n\n.alert.error {\n  background-color: #F44336; /* Error red */\n}\n\n.alert.warning {\n  background-color: #FFA000; /* Warning amber */\n}\n\n.alert.info {\n  background-color: #2196F3; /* Info blue */\n}\n\n@keyframes slideInDown {\n  from {\n    transform: translate(-50%, -100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, 0);\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
