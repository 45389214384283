// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-listings-title{
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-listings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.user-listings-page h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.listings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.listing-wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.close-listing-button {
  width: 100%;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-listing-button:hover {
  background-color: #d32f2f;
}

.listing-closed-message {
  background-color: #f1f1f1;
  color: #666;
  padding: 10px;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

@media (max-width: 768px) {
  .listings-container {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/UserListingsPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".user-listings-title{\n  font-size: 30px;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 30px;\n  margin-top: 10px;\n  text-align: center;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.user-listings-page {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.user-listings-page h1 {\n  font-size: 28px;\n  color: #333;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.listings-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 20px;\n}\n\n.listing-wrapper {\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  overflow: hidden;\n}\n\n.close-listing-button {\n  width: 100%;\n  padding: 10px;\n  background-color: #f44336;\n  color: white;\n  border: none;\n  border-radius: 0 0 8px 8px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.close-listing-button:hover {\n  background-color: #d32f2f;\n}\n\n.listing-closed-message {\n  background-color: #f1f1f1;\n  color: #666;\n  padding: 10px;\n  text-align: center;\n  border-radius: 0 0 8px 8px;\n}\n\n@media (max-width: 768px) {\n  .listings-container {\n    grid-template-columns: 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
